import { RouteRecordRaw } from "vue-router";

export const shareTargetRoute: RouteRecordRaw = {
  path: "/share-target",
  name: "shareTarget",
  component: () => null,
  beforeEnter: (route) => {
    const url =
      parseUrl(route.query.url?.toString()) ||
      parseUrl(route.query.text?.toString()) ||
      parseUrl(route.query.title?.toString());
    return { name: "newMeal", params: { url: url?.href } };
  },
};

function parseUrl(url: string | undefined) {
  if (!url) return undefined;
  try {
    return new URL(url);
  } catch (e) {
    return undefined;
  }
}
