/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DaysQuery } from "@/generated/graphql";
import { DateOnly } from "@/services/date";
import { ToReferenceFunction } from "@apollo/client/cache/core/types/common";
import { FieldFunctionOptions } from "@apollo/client/core";
import { compareAsc } from "date-fns";
import { Field } from "./apollo";

type DayFields = readonly Field<DaysQuery["days"][0]>[];

function makeEmptyDay(date: string, toReference: ToReferenceFunction) {
  const day: DaysQuery["days"][0] = {
    __typename: "Day" as const,
    date,
    mealsPlanned: [],
  };
  return toReference(day, true)!;
}
function getDay(
  date: string,
  days: DayFields,
  { readField, toReference }: FieldFunctionOptions
) {
  return (
    days.find((d) => readField<string>("date", d) == date) ||
    makeEmptyDay(date, toReference)
  );
}

export function getDays(
  incoming: DayFields,
  from: string,
  to: string,
  options: FieldFunctionOptions
) {
  const days = [];
  for (const date of DateOnly.range(from, to)) {
    const day = getDay(date.valueOf(), incoming, options);
    days.push(day);
  }
  return days;
}

export function mergeDays(
  existing: DayFields | undefined,
  incoming: DayFields,
  { readField, cache }: FieldFunctionOptions
) {
  return [...(existing || []), ...incoming]
    .sort((a, b) =>
      compareAsc(
        new Date(readField<string>("date", a)!),
        new Date(readField<string>("date", b)!)
      )
    )
    .filter((v, i, a) =>
      i == 0 ? true : cache.identify(v) != cache.identify(a[i - 1])
    );
}
