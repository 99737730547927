const serverUrl =
  process.env.NODE_ENV == "production"
    ? "https://tris.spiegelass.net"
    : "http://localhost:4000";

export const config = {
  serverUrl,
  apolloUrl: serverUrl + "/graphql",
  loginUrl: serverUrl + "/auth/login",
};
