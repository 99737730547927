import App from "@/App.vue";
import "@/assets/tailwind.css";
import { apolloClient, apolloProvider } from "@/graphql/apollo";
import "@/registerServiceWorker";
import router from "@/router";
import { store } from "@/services/store";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApp } from "vue";

const app = createApp(App);
app.config.globalProperties.store = store;
app.use(router);
app.use(apolloProvider); // Option API
app.provide(DefaultApolloClient, apolloClient); // Composition API
app.directive("focus", {
  mounted: (el: HTMLElement) => el.focus(),
});
app.mount("#app");

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    store: typeof store;
  }
}
