import { format, lightFormat } from "date-fns";
import { de } from "date-fns/locale";

// https://date-fns.org/docs/format
export function formatDate(dateString: string, long = false) {
  const date = new Date(dateString);
  const formatString = long ? "EEE dd.MM.yyyy" : "EEE dd.MM.";
  return format(date, formatString, { locale: de });
}

// function immutable() {
//   return (
//     target: unknown,
//     propertyKey: string,
//     descriptor: PropertyDescriptor
//   ) => {
//     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//     const original = descriptor.value!;
//     descriptor.value = (...args: never) => {
//       debugger;
//       const newDate = new DateOnly(oldDate.valueOf());
//       original.apply(newDate, args);
//       return newDate;
//     };
//     return descriptor;
//   };
// }

type Options = { addDays?: number };
export class DateOnly {
  date: Date;

  constructor(dateString?: string, { addDays }: Options = {}) {
    this.date = dateString ? new Date(dateString) : new Date();
    this.date.setUTCHours(0, 0, 0, 0);
    if (addDays) this.addDays(addDays);
  }

  clone(options?: Options) {
    return new DateOnly(this.valueOf(), options);
  }
  addDays(value: number) {
    this.date.setUTCDate(this.date.getUTCDate() + value);
  }

  format({ long = false }) {
    const formatString = long ? "EEE dd.MM.yyyy" : "EEE dd.MM.";
    return format(this.date, formatString, { locale: de });
  }
  valueOf() {
    return lightFormat(this.date, "yyyy-MM-dd");
  }

  static *range(from: string, to: string) {
    const date = new DateOnly(from);
    const dateTo = new DateOnly(to);
    while (true) {
      if (date.date > dateTo.date) return;
      yield date.clone();
      date.addDays(1);
    }
  }
}
