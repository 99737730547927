/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DaysQuery, DaysQueryVariables } from "@/generated/graphql";
import {
  ApolloClient,
  ApolloLink,
  concat,
  FieldFunctionOptions,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
  Reference,
  StoreObject,
} from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { config } from "../services/config";
import { getDays, mergeDays } from "./mergeDays";

export type Field<T> = T | Reference;
const defineFieldPolicy = <
  T extends StoreObject,
  TVars = Record<string, unknown>
>(
  data: FieldPolicy<
    Field<T>[],
    Field<T>[],
    Field<T>[],
    FieldFunctionOptions<Record<string, unknown>, TVars>
  >
) => data;

const cache = new InMemoryCache({
  typePolicies: {
    Day: {
      keyFields: ["date"],
    },
    Meal: {
      keyFields: ["id"],
    },
    Query: {
      fields: {
        days: defineFieldPolicy<DaysQuery["days"][0], DaysQueryVariables>({
          keyArgs: [],
          merge: (existing, incoming, options) => {
            const variables = options.variables;
            // cache update
            if (!variables?.from || !variables?.to) return incoming;
            const days = getDays(
              incoming,
              variables.from,
              variables.to,
              options
            );
            return mergeDays(existing, days, options);

            //
            //
            // // cache update
            // if (!variables?.from && !variables?.to) return incoming;
            // return [...(existing || []), ...incoming]
            //   .sort((a, b) =>
            //     compareAsc(
            //       new Date(readField<string>("date", a)!),
            //       new Date(readField<string>("date", b)!)
            //     )
            //   )
            //   .filter((v, i, a) =>
            //     i == 0 ? true : cache.identify(v) != cache.identify(a[i - 1])
            //   );
          },
        }),
        mealsBookmarked: {
          merge: false,
        },
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: config.apolloUrl,
  credentials: "include",
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    // const csrfToken = generateCsrfToken();
    return {
      headers: {
        ...headers,
        // "x-csrf-token": csrfToken,
      },
    };
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  cache: cache,
  link: concat(authLink, httpLink),
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
