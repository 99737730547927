import { reactive } from "vue";
import { DateOnly } from "./date";

export const store = {
  state: reactive({
    pagination: {
      days: {
        from: null as DateOnly | null,
        to: null as DateOnly | null,
        fromReverse: null as DateOnly | null,
        toReverse: null as DateOnly | null,
      },
    },
  }),
};
