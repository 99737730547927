
import { SessionQuery } from "@/generated/graphql";
import { config } from "@/services/config";
import { UseQueryReturn } from "@vue/apollo-composable";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoginView",
  props: {
    query: {
      type: Object as () => UseQueryReturn<SessionQuery, undefined>,
      required: true,
    },
  },
  computed: {
    loginUrl: () =>
      config.loginUrl +
      "?return_to=" +
      encodeURIComponent(window.location.href),
  },
});
