import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { shareTargetRoute } from "./shareTarget";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },
  {
    path: "/bookmarked",
    name: "bookmarked",
    component: () =>
      import(
        /* webpackChunkName: "BookmarkedView" */ "../views/BookmarkedView.vue"
      ),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "SearchView" */ "../views/SearchView.vue"),
  },
  {
    path: "/suggestions",
    name: "suggest",
    component: () =>
      import(/* webpackChunkName: "SuggestView" */ "../views/SuggestView.vue"),
  },
  shareTargetRoute,
  {
    path: "/meal/:id",
    name: "meal",
    props: (route) => ({ id: parseInt(route.params.id as string) }),
    component: () =>
      import(/* webpackChunkName: "MealView" */ "../views/MealView.vue"),
  },
  {
    path: "/meal/:id/edit",
    name: "editMeal",
    props: (route) => ({
      mealId: parseInt(route.params.id as string),
    }),
    component: () =>
      import(
        /* webpackChunkName: "EditMealView" */ "../views/EditMealView.vue"
      ),
  },
  {
    path: "/day/:date",
    name: "day",
    props: (route) => ({ date: route.params.date as string }),
    component: () =>
      import(/* webpackChunkName: "DayView" */ "../views/DayView.vue"),
  },
  {
    path: "/plan",
    name: "plan",
    props: (route) => ({
      mealId: route.params.mealId,
      date: route.params.day,
      removeOldDate: route.params.oldDate,
    }),
    component: () =>
      import(/* webpackChunkName: "PlanView" */ "../views/PlanView.vue"),
  },
  {
    path: "/new",
    name: "newMeal",
    props: (route) => ({ mealId: null, url: route.params.url }),
    component: () =>
      import(
        /* webpackChunkName: "EditMealView" */ "../views/EditMealView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
